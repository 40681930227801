<template>
    <div class="merchantadd-container position-relative">
        <div class="merchant-form">
            <el-form label-width="120px" :model="merchantList" ref="addForm" :inline="true"
                :hide-required-asterisk="true">
                <div>
                    <div class="d-flex a-center mb-30">
                        <img style="width:3px;height:14px" class="mr-15" src="@/assets/image/line.png" alt="">
                        <div class="main-color font-17">业务信息</div>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item label="供应商" prop="merchantId">
                                <div>{{ merchantList.merchantName }}</div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="采购员" prop="merchantPhone">
                                <div>{{ merchantList.merchantPhone }}</div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="line-style mb-30"></div>
                    <div class="d-flex a-center mb-30">
                        <img style="width:3px;height:14px" class="mr-15" src="@/assets/image/line.png" alt="">
                        <div class="main-color font-17">商品信息</div>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item label="商品名称" prop="areaIds">
                                <div>{{ merchantList.areaNames }}</div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item label="商品主图" prop="areaIds">
                                <div>{{ merchantList.areaNames }}</div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="视频" prop="areaIds">
                                <div>{{ merchantList.areaNames }}</div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
        
        <div class="fy1">
            <el-button @click="goBack">返回</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            merchantList: {
            },
            moneyall:0,
            moneycountall: 0,
            spc:[],
            goodsData: [ //商品信息
            ],
            word:'',
            goodsWeight:'',
            moneyData:[],
            cass:[],
            goodsNameData:[],
            loading: true,
        }
    },
    created() {
        this.word = this.$route.query.word
        // this.getDetail()
    },
    methods: {
        // 获取详情
        getDetail() {
            this.$api.coldChain.voucherDetail({jobOrderId:this.$route.query.id}).then((res) => {
                this.merchantList = this.$tool.DeepCopy({}, res.data)
                this.areaNames = res.data.areaNames
                this.goodsWeight = res.data.goodsWeight
                this.goodsData = this.merchantList.goodsDetail;
                this.moneyData = this.merchantList.attachDetail;
                this.goodsData.forEach(item => {
                    this.spc.push(false)
                    this.cass.push(item.isCarcass==1?true:false)
                })
                this.loading = false;
            });
        },
        goBack(){
            this.$route.params.word=this.word;
            this.$router.back()
        },
    }
}
</script>

<style lang="less">
.merchantadd-container {
    padding: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    .merchant-form{
        flex-grow: 1;
    }
    .warehouse-wrapper {
        width: 302px;
        height: 37px;
        line-height: 37px;
        text-align: center;
        background: #F2FCFF;
        border-radius: 2px;
        border: 1px dashed #349EE6;
        margin-bottom: 20px;
        cursor: pointer;

        .plus {
            width: 14px;
            height: 14px;
        }
    }

    .warehouse-container {
        .warehouse-box {
            border-radius: 2px;
            border: 1px solid #DFE3EF;
            margin-bottom: 20px;
            padding: 11px 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                line-height: 1;
            }

            .box-img {
                width: 14px;
                height: 14px;
                cursor: pointer;
            }
        }

    }

    .footerbtn {
        position: fixed;
        right: 20px;
        top: 13%;
    }

    .allmoney {
        position: fixed;
        left: 210px;
        right: 20px;
        height: 76px;
        padding: 0 30px;
        line-height: 76px;
        background: #FFFCF9;
        box-shadow: 0px -3px 8px 0px rgba(255,140,89,0.3);
        border-radius: 6px;
        bottom: 40px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

    }

    .allmoney-font {
        font-size: 18px;
    }
    .auto-table-flex .el-table .el-table__footer-wrapper .cell{
        color: #E65A34;
    }
}
</style>
